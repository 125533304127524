import { useState, useEffect, useCallback  } from "react";
import { useNavigate, Link } from "react-router-dom";
import { loadFull }  from 'tsparticles';
import Particles  from 'react-tsparticles';
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaPen, FaTrash, FaClipboard, FaUserFriends } from "react-icons/fa";


function OnlineGame() {
  const [transactions, setTransactions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [newName, setNewName] = useState('');
  const [participants, setParticipants] = useState([]);
  const [preferences, setPreferences] = useState([]);

  const navigate = useNavigate();

  const particlesInit = useCallback(async engine => {
      console.log(engine);
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      await console.log(container);
  }, []);

  const addParticipant = () => {
    setParticipants([...participants, {
      name: newName,
      buyIn: 0,
      buyOut: 0,
      net: 0
    }]);
  }

  const handleParticipantNameChange = (e) => {
    setNewName(e.target.value);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    const field = id.split('-')[0];
    const index = id.split('-')[1];
    console.log('id, value', id.split('-')[0], id.split('-')[1], value);
    participants[index][field] = value;
    participants[index]['net'] = participants[index]['buyOut'] - participants[index]['buyIn'];
    console.log('net', participants[index]['net']);
    setParticipants([...participants]);
  }

  const calculate = (players, preferences = []) => {
    let ledger = {};
    players.forEach(player => {
      if (player.net != 0) {
        if (player.name in ledger) {
          ledger[player.name] += player.net;
        } else {
          ledger[player.name] = player.net;
        }
      }
    })
    if (Object.values(ledger).reduce((a, b) => a + b, 0) != 0) {
      console.log({players})
      console.log({ledger})
      alert("Ledger does not sum to 0");
      return [];
    }
    var newLedger = { ...ledger };
    let _transactions = [];
    let cnt = 0;
    while (Object.keys(newLedger).length > 0) {
      cnt++;
      if(cnt> 100) break;
      var maxPlayer, minPlayer;
      let flag = 1;
      if(preferences.length > 0) {
        if(newLedger[preferences[0].paynee] && newLedger[preferences[0].payer]) {
          flag = 0;
          maxPlayer = preferences[0].paynee;
          minPlayer = preferences[0].payer;
        }
        preferences.shift();
      }
      if(flag === 1){
        maxPlayer = Object.keys(newLedger).reduce((a, b) =>
          newLedger[a] > newLedger[b] ? a : b
        );
        minPlayer = Object.keys(newLedger).reduce((a, b) =>
          newLedger[a] > newLedger[b] ? b : a
        );
      }
      var amount = Math.min(
        newLedger[maxPlayer],
        Math.abs(newLedger[minPlayer])
      );
      newLedger[maxPlayer] -= amount;
      newLedger[minPlayer] += amount;
      if (newLedger[maxPlayer] === 0) {
        delete newLedger[maxPlayer];
      }
      if (newLedger[minPlayer] === 0) {
        delete newLedger[minPlayer];
      }
      _transactions = [
        ..._transactions,
        {
          minPlayer,
          maxPlayer,
          amount: amount.toFixed(2),
        },
      ];
    }
    return _transactions;
  }

  const handleCalculate = () => {
    let transactions = calculate(participants);
    setTransactions(transactions);
  }

  const handleChangePreference = (payer, paynee) => {
    console.log('paynee', paynee);
    console.log('payer', payer);
    setPreferences([...preferences, {
      paynee, payer
    }])
  }

  const reCalculate = () => {
    let transactions = calculate(participants, preferences);
    setTransactions(transactions);
  }
  return (
    <div className="home-container">
      <div className="absolute top-5 right-5 z-[100] ">
        <Link to="/"><i className="fa fa-sign-out text-2xl text-[#999] cursor-pointer hover:text-3xl hover:text-[#fff]"></i></Link>
      </div>
      {modalVisible ? (
        <div className="preference-modal-back">
          <div className="preference-modal-container2 backdrop-blur-md relative">
            <div className="preference-title">Preference</div>
            {
              participants.filter(participant => participant.net < 0).map((_participant, index) => {
                return <div className="preference-row" key={index}>
                <div className="selector-div">{ _participant.name }</div>
                <div className="selected-div">
                  <select onChange={(e) => handleChangePreference(_participant.name, e.target.value)}>
                    <option disabled selected>-- Not Selected --</option>
                    {
                      participants.filter(participant => participant.net > 0).map((participant, index) => {
                        return <option value={participant.name} key={index}>{participant.name}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              })
            }
            <div className="modal-button-div">
              <span 
                className="modal-button modal-apply-button"
                onClick={reCalculate}
              >
                Apply
              </span>
              <span
                className="modal-button modal-cancel-button"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="calculate-container">
        <div className="layer1_background layer_background gradient-style"></div>
        <div className="title-div font-family4">Home Game Ledger</div>
        <div className="text-white mb-2 text-1xl font-bold w-full text-center">
          Insert the participants here.
        </div>
        <div className="flex justify-center mb-6">
          <input
            className="backdrop-blur-sm bg-[#FFFFFF15] min-w-[250px] max-[600px]:min-w-[200px] text-white h-8 outline-none p-1 rounded-md hover:bg-[#FFFFFF22] transition-all"
            onChange={handleParticipantNameChange}
          />
          <button
            className="backdrop-blur-sm bg-[#FFFFFF15] font-bold text-white w-8 h-8 ml-3 flex justify-center items-center rounded-md hover:bg-[#FFFFFF22] transition-all"
            onClick={addParticipant}
          >
            <i className="fa fa-user-plus"></i>
          </button>
        </div>
        <div className="body">
          <div className="backdrop-blur-sm pay-div w-80p">
            <div className="bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all relative p-5 min-h-[400px]">
              <div className="table-row pt-2 pb-4">
                <div className="title w-40p">Participants</div>
                <div className="title w-20p">Buy In</div>
                <div className="title w-20p">Buy Out</div>
                <div className="title w-20p">Profit</div>
              </div>
              <div className="table-content overflow-y-auto">
                {
                  participants.map((participant, index) => {
                    return <div className="table-row" key={index}>
                      <div className="input w-40p"><input id={`name-${index}`} value={participant.name} onChange={handleChange}></input></div>
                      <div className="input w-20p"><input type="number" id={`buyIn-${index}`} onChange={handleChange}></input></div>
                      <div className="input w-20p"><input type="number" id={`buyOut-${index}`} onChange={handleChange}></input></div>
                      <div className="input w-20p"><input disabled value={participant.net}></input></div>
                    </div>
                  })
                }
              </div>
              <div className="button">
                <div className="calculate" onClick={handleCalculate}><i className="fa fa-calculator"></i></div>
                <div className="prefer" onClick={() => setModalVisible(true)}><FaUserFriends className="m-auto" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="result-container">
      <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 60,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 400,
                        },
                        value: 30,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "square",
                    },
                    size: {
                        value: { min: 1, max: 3 },
                    },
                },
                detectRetina: true,
            }}
        />
        <div className="background-effect">
          {/* <div className="select">
            <select
              onChange={(e) => selectLedger(e.target.value)}
              className="cursor-pointer font-family3 outline-none backdrop-blur-sm bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all text-xl w-60 p-1"
            >
              {ledgerList.length === 0 ? (
                <option className="text-black">'No Ledger'</option>
              ) : null}
              {ledgerList.map((ledger, index) => {
                return (
                  <option className="text-black" value={ledger.index}>
                    {ledger.title}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="result-body">
            <div className="backdrop-blur-sm pay-div">
              <div className="bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all relative">
                <div className="text-center py-5 text-4xl font-medium round-title-div">
                    <span className="font-family4"> Result </span>
                </div>
                
                <div className="px-2 pt-2  w-full min-h-[400px] overflow-y-auto">
                  <div className="flex w-full">
                    <div className="left-name2-title flex justify-center">
                      Loser
                    </div>
                    <div className="middle-money2-title flex justify-center">
                      $
                    </div>
                    <div className="right-name2-title flex justify-center">
                      Winner
                    </div>
                  </div>
                  {
                    transactions.map((transaction, index) => {
                      return <div className="flex w-full items-center" key={index}>
                        <div className="left-name2">{transaction.minPlayer}</div>
                        <div className="middle-money2">${transaction.amount}</div>
                        <div className="right-name2">{transaction.maxPlayer}</div>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
  );
}

export default OnlineGame;
