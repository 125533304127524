import { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaPen, FaTrash, FaClipboard, FaUserFriends } from "react-icons/fa";
import { IoMdRefresh } from "react-icons/io";
import win from "../assets/win.png";
import lose from "../assets/lose.png";
import moneybag from "../assets/money_bag.png";
import modalImage from "../assets/modal_image.jpg";
import chipImage from "../assets/chip.png";
import { loadFull }  from 'tsparticles';
import Particles  from 'react-tsparticles';

function OnlineGame() {
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [ledgerList, setLedgerList] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [selectedLedgerIndex, setSelectedLedgerIndex] = useState();
  const [editedTitle, setEditedTitle] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const navigate = useNavigate();
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      await console.log(container);
  }, []);
  useEffect(() => {
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    if (ledgers)
      setLedgerList(
        ledgers.map((ledger) => {
          return {
            title: ledger.title,
            index: ledger.index,
            link: ledger.link,
          };
        })
      );
  }, []);

  const handleChangeEditedTitle = (e) => {
    setEditedTitle(e.target.value);
  };
  const handleChangeTitle = () => {
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    if (ledgers) {
      let ledger = ledgers.find(
        (ledger) => ledger.index === selectedLedgerIndex
      );
      ledger.title = editedTitle;
      localStorage.setItem("ledgers", JSON.stringify(ledgers));
      setLedgerList(
        ledgers.map((ledger) => {
          return {
            title: ledger.title,
            index: ledger.index,
            link: ledger.link,
          };
        })
      );
    }
    setIsEditingTitle(false);
    setTitle(editedTitle);
  };
  function getCurrentDateTime() {
    var currentDate = new Date();

    var year = currentDate.getFullYear();
    var month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    var day = ("0" + currentDate.getDate()).slice(-2);

    var hours = ("0" + currentDate.getHours()).slice(-2);
    var minutes = ("0" + currentDate.getMinutes()).slice(-2);

    var formattedDateTime =
      year + "-" + month + "-" + day + " " + hours + ":" + minutes;

    return formattedDateTime;
  }

  const calculate = (players, preferences = []) => {
    let ledger = {};
    for (var player of Object.values(players)) {
      if (player.net != 0) {
        if (player.names[0] in ledger) {
          ledger[player.names[0]] += player.net;
        } else {
          ledger[player.names[0]] = player.net;
        }
      }
    }
    if (Object.values(ledger).reduce((a, b) => a + b, 0) != 0) {
      alert("Ledger does not sum to 0");
    }
    var newLedger = { ...ledger };
    let _transactions = [];
    let cnt = 0;
    while (Object.keys(newLedger).length > 0) {
      cnt++;
      if(cnt> 100) break;
      var maxPlayer, minPlayer;
      let flag = 1;
      if(preferences.length > 0) {
        if(newLedger[preferences[0].paynee] && newLedger[preferences[0].payer]) {
          flag = 0;
          maxPlayer = preferences[0].paynee;
          minPlayer = preferences[0].payer;
        }
        preferences.shift();
      }
      if(flag === 1){
        maxPlayer = Object.keys(newLedger).reduce((a, b) =>
          newLedger[a] > newLedger[b] ? a : b
        );
        minPlayer = Object.keys(newLedger).reduce((a, b) =>
          newLedger[a] > newLedger[b] ? b : a
        );
      }
      var amount = Math.min(
        newLedger[maxPlayer],
        Math.abs(newLedger[minPlayer])
      );
      newLedger[maxPlayer] -= amount;
      newLedger[minPlayer] += amount;
      if (newLedger[maxPlayer] === 0) {
        delete newLedger[maxPlayer];
      }
      if (newLedger[minPlayer] === 0) {
        delete newLedger[minPlayer];
      }
      _transactions = [
        ..._transactions,
        {
          minPlayer,
          maxPlayer,
          amount: (amount / 100).toFixed(2),
        },
      ];
    }
    return _transactions;
  }

  const addLedger = async () => {
    let { data } = await axios.post("/api/data", { link });
    var players = data.playersInfos;
    let _transactions = calculate(players);
    setTransactions([]);
    setTransactions(_transactions);
    let defaultTitle = getCurrentDateTime();
    setTitle(defaultTitle);
    if (!localStorage.getItem("ledgers")) {
      localStorage.setItem("ledgers", JSON.stringify([]));
    }
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    let index = ledgers.length;
    ledgers.unshift({
      index: index,
      title: defaultTitle,
      date: defaultTitle,
      link,
      transactions: _transactions,
      players: Object.values(players)
    });
    localStorage.setItem("ledgers", JSON.stringify(ledgers));

    setParticipants(Object.values(players));

    setLedgerList([
      {
        index,
        title: defaultTitle,
        link: link,
      },
      ...ledgerList,
    ]);
    setSelectedLedgerIndex(index);
    // transactions.sort(function(a, b) {
    //   return a.toLowerCase().localeCompare(b.toLowerCase());
    // });
  };

  const reCalculate = () => {
    let _transactions = calculate(participants, preferences);
    setTransactions(_transactions);
    setModalVisible(false);
    setPreferences([]);
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    if (ledgers) {
      let ledger = ledgers.find(
        (ledger) => ledger.index === selectedLedgerIndex
      );
      ledger.transactions = _transactions;
      localStorage.setItem("ledgers", JSON.stringify(ledgers));
    }
  }

  const handleChange = (e) => {
    setLink(e.target.value);
  };
  const selectLedger = (index) => {
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    if (ledgers) {
      let ledger = ledgers.find((ledger) => ledger.index == index);
      setTransactions(ledger.transactions);
      setTitle(ledger.title);
      setSelectedLedgerIndex(ledger.index);
      setParticipants(ledger.players);
    }
  };
  const deleteLedger = () => {
    let ledgers = JSON.parse(localStorage.getItem("ledgers"));
    if (ledgers) {
      let newLedgers = [];
      ledgers.forEach((ledger) => {
        if (ledger.index !== selectedLedgerIndex) newLedgers.push(ledger);
      });
      localStorage.setItem("ledgers", JSON.stringify(newLedgers));
      setTransactions([]);
      setTitle("");
      setSelectedLedgerIndex(undefined);
      let newLedgerList = [];
      ledgerList.forEach((ledger) => {
        if (ledger.index !== selectedLedgerIndex) {
          newLedgerList.push(ledger);
        }
      });
      setLedgerList(newLedgerList);
    }
  };

  const handleChangePreference = (payer, paynee) => {
    console.log('paynee', paynee);
    console.log('payer', payer);
    setPreferences([...preferences, {
      paynee, payer
    }])
  }
  return (
    <div className="flex justify-center App relative">
      <div className="w-full absolute left-0 top-0 h-[1200px] online-app opacity-50"></div>
      <div className="w-full absolute left-0 top-0 h-[1200px] bg-gradient-to-b from-[#000000] via-[#115190] to-[#1473c0] opacity-90"></div>
      <div className="absolute top-5 right-5 z-[100] ">
        <Link to="/"><i className="fa fa-sign-out text-2xl text-[#999] cursor-pointer hover:text-3xl hover:text-[#fff]"></i></Link>
      </div>
      {modalVisible ? (
        <div className="preference-modal-back">
          <div className="preference-modal-container backdrop-blur-md relative">
            {/* <div className="modal-image-div">
              <img src={modalImage} alt="modal"/>
            </div> */}
            <div className="preference-title">Preferences</div>
            {
              participants.filter(participant => participant.net < 0).map(_participant => {
                return <div className="preference-row" key={_participant.id}>
                <div className="selector-div">{ _participant.names[0] }</div>
                <div className="selected-div">
                  <select onChange={(e) => handleChangePreference(_participant.names[0], e.target.value)}>
                    <option disabled selected>-- Not Selected --</option>
                    {
                      participants.filter(participant => participant.net > 0).map(participant => {
                        return <option value={participant.names[0]} key={participant.id}>{participant.names[0]}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              })
            }
            <div className="modal-button-div">
              <span className="modal-button modal-apply-button opacity-80" onClick={reCalculate}>Apply</span>
              <span
                className="modal-button modal-cancel-button opacity-80"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </span>
            </div>
            {/* <div className="modal-chip-image">
              <img src={chipImage} />
            </div> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            background: {
                color: {
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "repulse",
                    },
                    onHover: {
                        enable: false,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 100,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 100,
                    enable: false,
                    opacity: 0.5,
                    width: 1,
                },
                collisions: {
                    enable: true,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 0.5,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 400,
                    },
                    value: 10,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "square",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }}
      />
      <ReactTooltip id="preference" />
      <ReactTooltip id="edit" />
      <ReactTooltip id="delete" />
      <div className="md:pt-[50px] lg:pt-[50px] md:w-[800px] lg:w-[1000px] pt-[30px] overflow-y-auto">
        <div
          className="flex items-center justify-center title-font text-white relative z-[1000]"
          style={{ fontFamily: "'Brush Script MT', cursive" }}
        >
          Online Game Ledger
        </div>
        <div className="text-white mb-2 text-1xl font-bold relative z-[100]">
          Insert the poker now link here
        </div>
        <div className="flex justify-center mb-6">
          <input
            onChange={handleChange}
            className="backdrop-blur-sm border border-[#ffffff30] hover:border-[#ffffff50] bg-[#FFFFFF15] min-w-[250px] text-white h-8 outline-none p-1 rounded-md hover:bg-[#FFFFFF22] transition-all"
          />
          <button
            onClick={addLedger}
            className="backdrop-blur-sm border border-[#ffffff30] hover:border-[#ffffff50] bg-[#FFFFFF15] font-bold text-white w-8 h-8 ml-3 flex justify-center items-center rounded-md hover:bg-[#FFFFFF22] transition-all"
          >
            <i className="fa fa-cloud-download"></i>
          </button>
        </div>
        <div className="pb-6 select-div">
          <select
            onChange={(e) => selectLedger(e.target.value)}
            className="font-family1 outline-none backdrop-blur-sm bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all border border-[#ffffff30] hover:border-[#ffffff50] text-xl w-60 p-1"
          >
            {ledgerList.length === 0 ? (
              <option className="text-black">'No Ledger'</option>
            ) : null}
            {ledgerList.map((ledger, index) => {
              return (
                <option className="text-black" value={ledger.index}>
                  {ledger.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="main-body">
          <div className="lg:w-[300px] md:w-[250px] min-w-[200px] max-h-[400px] overflow-auto text-left list-div">
            <div className="backdrop-blur-sm bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all border border-[#ffffff30] hover:border-[#ffffff50] mb-2 px-3 py-2">
              {ledgerList.length === 0 ? "No Ledger" : ""}
              {ledgerList.map((ledger, index) => {
                return (
                  <div
                    onClick={() => selectLedger(ledger.index)}
                    className={`font-family1 cursor-pointer text-xl ${
                      ledgerList.length !== 1 ? "border-b" : ""
                    } border-solid border-gray-600`}
                  >
                    {ledger.title}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="backdrop-blur-sm pay-div">
            <div className="bg-[#FFFFFF15] text-white rounded-md hover:bg-[#FFFFFF22] transition-all border border-[#ffffff30] hover:border-[#ffffff50] relative">
              <div className="text-center py-5 text-4xl font-medium round-title-div">
                {isEditingTitle ? (
                  <div className="">
                    <div className="input-div">
                      <input
                        onChange={handleChangeEditedTitle}
                        className="round-title-input"
                      ></input>
                      <span
                        onClick={handleChangeTitle}
                        className="title-input-button"
                      >
                        <i className="fa fa-save"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="font-family1">{title}</span>
                )}
              </div>
              {selectedLedgerIndex !== undefined ? (
                <div className="absolute right-0 top-1">
                  {/* <button
                  data-tooltip-id="copy"
                  data-tooltip-content="Copy"
                  className="inline-block bg-transparent font-bold text-white w-8 h-8 rounded-md hover:bg-[#FFFFFF22] transition-all"
                >
                  <FaClipboard className="m-auto" />
                </button> */}
                  <button
                    onClick={() => setModalVisible(true)}
                    data-tooltip-id="preference"
                    data-tooltip-content="Preference"
                    className="inline-block bg-transparent font-bold text-white w-8 h-8 rounded-md hover:bg-[#FFFFFF22] transition-all"
                  >
                    <FaUserFriends className="m-auto" />
                  </button>
                  <button
                    onClick={() => setIsEditingTitle(true)}
                    data-tooltip-id="edit"
                    data-tooltip-content="Edit"
                    className="inline-block bg-transparent font-bold text-white w-8 h-8 rounded-md hover:bg-[#FFFFFF22] transition-all"
                  >
                    <FaPen className="m-auto" />
                  </button>
                  <button
                    onClick={deleteLedger}
                    data-tooltip-id="delete"
                    data-tooltip-content="Delete"
                    className="inline-block bg-transparent font-bold text-white w-8 h-8 rounded-md hover:bg-[#FFFFFF22] transition-all"
                  >
                    <FaTrash className="m-auto" />
                  </button>
                </div>
              ) : null}
              <div className="px-2 pt-2 pb-10 w-full">
                <div className="flex w-full border-b border-solid border-neutral-500 items-center">
                  <div className="left-name flex justify-center max-[768px]:text-[20px] text-[27px]">
                    Loser
                  </div>
                  <div className="right-name flex justify-center max-[768px]:text-[20px] text-[27px]">
                    Winner
                  </div>
                  <div className="middle-money flex justify-center font-family1 max-[768px]:text-[20px] text-[24px]">
                    $
                  </div>
                </div>
                {transactions.map((transaction, i) => {
                  return (
                    <div className="flex w-full items-center border-b border-solid border-neutral-500" key={i}>
                      <div className="w-[40%] text-center max-[768px]:text-[16px] text-[27px] text-white font-extrabold text-shadow-left">{transaction.minPlayer}</div>
                      <div className="w-[40%] text-center max-[768px]:text-[16px] text-[27px] text-white font-extrabold text-shadow-right">{transaction.maxPlayer}</div>
                      <div className="middle-money text-[24px] font-family2 max-[768px]:text-[16px]">${transaction.amount}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineGame;
