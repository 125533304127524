import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, useCallback  } from "react";

import { loadFull }  from 'tsparticles';
import Particles  from 'react-tsparticles';
import title from "../assets/pokersettler.png";
import image1 from "../assets/landing_image1.jpeg";
import image2 from "../assets/landing_image2.png";
import image3 from "../assets/landing_image3.jfif";
import image from "../assets/cards.png";

function Home() {
  const navigate = useNavigate();

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);


  return (
    <div className="flex max-lg:flex-col w-full h-[100vh] max-sm:h-[900px]  bg-gradient-to-r from-[#010c14] to-[#042c4d]">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            background: {
                color: {
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 100,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#ffffff",
                },
                links: {
                    color: "#ffffff",
                    distance: 100,
                    enable: false,
                    opacity: 0.5,
                    width: 1,
                },
                collisions: {
                    enable: true,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: false,
                    speed: 0.5,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 400,
                    },
                    value: 30,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 3 },
                },
            },
            detectRetina: true,
        }}
      />
      <div className="lg:w-1/2">
        <div className="flex flex-col text-white text-[150px] leading-[180px] font-bold pt-[10%] pl-[20%] max-[900px]:pl-[10%] max-[770px]:pl-[0] font-family1 italic max-sm:text-[70px] max-sm:leading-[90px] max-md:items-center text-sha1 opacity-20">
          <span>
            Poker
          </span>
          <span className="md:pl-20">
            Settler
          </span>
        </div>
      </div>
      <div className="lg:w-1/2 relative h-full max-lg:flex max-lg:justify-center max-lg:items-center max-sm:items-start pt-5">
        <div className="flex gap-x-5 lg:absolute lg:top-10 lg:right-10 max-md:justify-center max-[444px]:flex-col max-[444px]:gap-y-10">
          <Link to="/online">
            <div className="mod-button backdrop-blur-sm bg-white/30">
              Online Game
            </div>
          </Link>
          <Link to="/home">
            <div className="mod-button backdrop-blur-sm bg-white/30">
              Home Game
            </div>
          </Link>
        </div>
        <div className="absolute right-0 bottom-0 max-[728px]:top-0">
          <img src={image} className="image-opacity lg:min-h-[500px] lg:min-w-[800px] h-auto w-full" />
        </div>
      </div>
    </div>
  );
}

export default Home;
