import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import OnlineGame from './pages/OnlineGame';
import HomeGame from './pages/HomeGame';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/online" element={<OnlineGame />} />
      <Route path="/home" element={<HomeGame />} />
    </Routes>
  );
}

export default App;
